import prodexpVendorService from "../../services/prodexpvendor";
const getDefaultState = () => {
    return {
        ProdExpVendor: {
            ID: 0,
            RequestType: null,
            Title: null,
            Phone: null,
            EmployeeAgency: null,
            AgencyName: null,
            VendorBusinessName: null,
            IdentificationType: null,
            IdentificationName: null,
            USTaxID: null,
            Foreign: null,
            SocialSecurityNumber: null,
            IdentificationFiles: [],
            attachement: [],
            ACH: null,
            Country: null,
            AdrsLine1: null,
            ProductService: null,
            Businessdollars: null,
            Compensation: null,
            Accept: null,
            Vendor: [],
            SetupInfo: [],
            ServiceInfoFiles: [],
            ExternalVendorFiles: [],
            WireInfoSheets: [],
            CompletedForms: [],
            SetupInfoSource: [],
            AdditionalApproval: null,
            CompensationDollers: null,
            EmployeeVendorRelationship: [],
            VendorFormIncludeACHBankingInfo: null,
            AnyGiftOrCompensationOfferedToYouToPlaceOrder: null,
            TypeOfProductOrServiceProvidedByVendor: null,
            DollarsAnticipatedToConductAnnuallyByVendor: null,
            RemittanceAdviceEmail: null,
            EmployeeTitle: null,
            EmployeePhoneNumber: null,
            EmployeeVendorRelationshipDetails: {
                ID: 0,
                FormerEmployeeName: null,
                FamilyMemberName: null,
                FriendName: null,
                VendorRelationshipWithAgencyClient: null,
                VendorOfFormerEmployee: null,
                VendorWasFormerEmployeeOfAgency: null,
                VendorHasRelationshipWithAnotherAgencyEmployee: null,
                Other: null,
                ProductionVendorRequestID: 0
            },
            Status: null,
            InfoFromName: null,
            InfoFromTitle: null,
            InfoFromPhone: null,
            InfoFromSourceIsVerbal: false,
            InfoFromSourceIsWritten: false,
            InfoFromSourceVerbal: null,
            InfoFromSourceWritten: null,
            InvoicesImmediatePayment: null,
            InfoFromWrittenAttachment: [],
            Requestor: null,
            UpdatedBy: null,
            CreatedDate: null,
            UpdatedDate: null,
            OmniflowProjectID: null,
            OmniflowProcessID: null,
            CompletionDate: null,
            InActive: null,
            Files: [],
            IsEditDataLoaded: false,
            IsVendorReceive1099: null,
            IsIssuedNameEIN: null,
            IssuedName: null,
            VendorTIN: null,
            HasBankLetterInvoice: null,
            BankName: null,
            BankCountry: null,
            BankAddressLine1: null,
            BankAddressLine2: null,
            BankCity: null,
            BankState: null,
            BankZipCode: null,
            BankAccountName: null,
            BankAccountNumber: null,
            BankRoutingNumber: null,
            InvoiceCopies: [],
            FormerEmployeeReimbursement: null,
            Entity: null,
            EntityName: null,
            VendorRemittanceName: null,
            RemittanceCountry: null,
            StreetAddressLine1: null,
            StreetAddressLine2: null,
            RemittanceCity: null,
            RemittanceState: null,
            RemittanceZipCode: null,
            RemittanceEmail: null,
        },
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    ProdExpVendor: (state) => {
        return state.ProdExpVendor;
    },
};

// actions
const actions = {
    async GetFormData(st, id) {
        await prodexpVendorService
            .GetFormData(id, st.rootState.auth.idToken)
            .then((res) => {
                st.commit("loadVendorData", res.data);
                st.commit("loadVendorFiles", res.data);
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    AddRequestWithFiles(st, data) {
        return prodexpVendorService.AddRequestWithFiles(
            data,
            st.rootState.auth.idToken
        );
    },
    UpdateRequestWithFiles(st, { id, data }) {
        return prodexpVendorService.UpdateRequestWithFiles(
            id,
            data,
            st.rootState.auth.idToken
        );
    },
    GetRequestWithTaskDetails(st, id) {
        return prodexpVendorService.GetProductionVendorRequestWithTaskDetails(
            id,
            st.rootState.auth.idToken
        );
    },

    ApproveProdExpenseRequestLevel1(st, data) {
        return prodexpVendorService.ApproveProdExpVendorRequestLevel1(
            data,
            st.rootState.auth.idToken
        );
    },
    RejectProdExpVendorRequestLevel1(st, data) {
        return prodexpVendorService.RejectProdExpVendorRequestLevel1(
            data,
            st.rootState.auth.idToken
        );
    },
    ApproveProdExpenseRequestLevel2(st, data) {
        return prodexpVendorService.ApproveProdExpVendorRequestLevel2(
            data,
            st.rootState.auth.idToken
        );
    },
    RejectProdExpVendorRequestLevel2(st, data) {
        return prodexpVendorService.RejectProdExpVendorRequestLevel2(
            data,
            st.rootState.auth.idToken
        );
    },
    RejectVendorValidation(st, data) {
        return prodexpVendorService.RejectVendorValidation(
            data,
            st.rootState.auth.idToken
        );
    },
    ApproveProdExpenseRequestLevel3(st, data) {
        return prodexpVendorService.ApproveProdExpVendorRequestLevel3(
            data,
            st.rootState.auth.idToken
        );
    },
    PendingVendorApproveorComplete(st, data) {
        return prodexpVendorService.PendingVendorApproveorComplete(
            data,
            st.rootState.auth.idToken
        );
    },

    RejectProdExpVendorRequestLevel3(st, data) {
        return prodexpVendorService.RejectProdExpVendorRequestLevel3(
            data,
            st.rootState.auth.idToken
        );
    },
    AccountingApprove(st, data) {
        return prodexpVendorService.AccountingApprove(
            data,
            st.rootState.auth.idToken
        );
    },
    AccountingReject(st, data) {
        return prodexpVendorService.AccountingReject(
            data,
            st.rootState.auth.idToken
        );
    },
    CFOApprove(st, data) {
        return prodexpVendorService.CFOApprove(
            data,
            st.rootState.auth.idToken
        );
    },
    CFOReject(st, data) {
        return prodexpVendorService.CFOReject(
            data,
            st.rootState.auth.idToken
        );
    },
    CompleteProdExpVendorRequest(st, data) {
        return prodexpVendorService.CompleteProdExpVendorRequest(
            data,
            st.rootState.auth.idToken
        );
    },
    ReassignDirectorApproval(st, data) {
        return prodexpVendorService.ReassignDirectorApproval(data, st.rootState.auth.idToken);
    },
    GetAuditFormData(st, id) {
        return prodexpVendorService.GetAuditFormData(id, st.rootState.auth.idToken);
    },
    GetWorkflowStates(st, id) {
        return prodexpVendorService.GetWorkflowStates(
            id,
            st.rootState.auth.idToken
        );
    },
    GetWorkflowHistoryStates(st, id) {
        return prodexpVendorService.GetWorkflowHistoryStates(
            id,
            st.rootState.auth.idToken
        );
    },
    GetRequestorById(st, id) {
        return prodexpVendorService.GetRequestorById(
            id,
            st.rootState.auth.idToken
        );
    },

    DownloadUploadTemplate(st, data) {
        return prodexpVendorService.DownloadUploadTemplate(
            st.rootState.auth.idToken,
            data
        );
    },

    //CompleteProdExpVendorRequest
};

function GetFilesCategory() {
    return [
        "WireInfoSheets",
        "IdentificationFiles",
        "SetupInfoSource",
        "ServiceInfoFiles",
        "CompletedForms",
        "InvoiceCopies"
    ];
}

// mutations
const mutations = {
    reset(st) {
        const s = getDefaultState();
        Object.keys(st).forEach((d) => {
            st[d] = s[d];
        });
    },
    loadVendorData(state, data) {
        Object.assign(state.ProdExpVendor, data);
        state.ProdExpVendor.Status = data.Status;
        state.ProdExpVendor.Phone = data.EmployeePhoneNumber;
        state.ProdExpVendor.Entity = data.Entity;
        state.ProdExpVendor.EmployeeAgency = data.EmployeeAgency;
        state.ProdExpVendor.InfoFromPhone = data.InfoFromPhone;
        state.ProdExpVendor.ACH = data.VendorFormIncludeACHBankingInfo == true ? "YES" : "NO";
        state.ProdExpVendor.ProductService = data.TypeOfProductOrServiceProvidedByVendor;
        state.ProdExpVendor.Compensation = data.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true ? "YES" : "NO";
        state.ProdExpVendor.InfoFromWrittenAttachment = data.ServiceFiles;
        state.ProdExpVendor.IsVendorReceive1099 = data.IsVendorReceive1099 == true ? "YES" : "NO";
        state.ProdExpVendor.IsIssuedNameEIN = data.IsIssuedNameEIN == true ? "YES" : "NO";
        state.ProdExpVendor.HasBankLetterInvoice = data.HasBankLetterInvoice == true ? "YES" : "NO";
        state.ProdExpVendor.FormerEmployeeReimbursement = data.FormerEmployeeReimbursement == true ? "true" : "false";
        state.ProdExpVendor.EmployeeVendorRelationship = [];
        if (data.EmployeeVendorRelationship != null) {
            data.EmployeeVendorRelationship.forEach((val) => {
                state.ProdExpVendor.EmployeeVendorRelationship.push(val.RelationshipName);
            });
        }
    },
    loadVendorFiles(state, data) {
        if (data.Files.length > 0) {
            GetFilesCategory().forEach((file) => {
                if (typeof file == "undefined" || file == null) {
                    return;
                } else {
                    let files = data.Files.filter((catFile) => {
                        return catFile.Category == file;
                    });
                    if (files.length > 0) {
                        files.forEach((originalFile) => {
                            state.ProdExpVendor[file].push(originalFile);
                        });
                    }
                }
            });
        }
        state.ProdExpVendor.IsEditDataLoaded = true;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};